<template>
  <div class="box">
    <!-- 轮播图 -->
    <div class="block">
      <el-carousel :height="autoHeight" arrow="always">
        <el-carousel-item >
          <img src="../imgs/banner1.png" alt="四川鹭涛智控科技">
        </el-carousel-item>
        <el-carousel-item >
          <img src="../imgs/banner2.png" alt="四川鹭涛智控科技">  
        </el-carousel-item>
        <el-carousel-item >
          <img src="../imgs/banner3.png" alt="四川鹭涛智控科技">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 我们的优势 -->
    <div class="advantage">
      <img src="../imgs/wmdyx.png" alt="鹭涛的优势" class="title_img">
      <ul class="axazs">
        <li>
          <!-- <img src="../imgs/zxsp.png" alt="鹭涛在线视频"> -->
          <div class="gd">
            <img src="../imgs/1/1.png" alt="" class="a">
          </div>
          <div class="xz_img">
            <img src="../imgs/1/1-2.png" alt="" class="b">
            <img src="../imgs/1/1-4.png" alt="" class="c">
          </div>
          <div class="text">
            <p>在线视频</p>
            <span>随时随地查看现场<br/>方便快捷</span>
          </div>
        </li>
         <li>
          <div class="gd">
            <img src="../imgs/2/2.png" alt="" class="a">
          </div>
          <div class="xz_img">
            <img src="../imgs/2/2-2.png" alt="" class="b">
            <img src="../imgs/2/2-3.png" alt="" class="c">
          </div>
          <div class="text">
            <p>数据汇总</p>
            <span>云数据储存<br/>个性化筛选，生成报表</span>
          </div>
        </li>
         <li>
          <div class="gd">
            <img src="../imgs/3/3.png" alt="" class="a">
          </div>
          <div class="xz_img">
            <img src="../imgs/3/3-2.png" alt="" class="b">
            <img src="../imgs/3/3-3.png" alt="" class="c">
          </div>
          <div class="text">
            <!-- <p>安全监控</p>
            <span>有害气体监控<br/>沉降预警</span> -->
            <p>安全监控</p>
            <span>有害气体监控<br/>沉降预警</span>
          </div>
        </li>
         <li>
          <div class="gd">
            <img src="../imgs/4/4.png" alt="" class="a">
          </div>
          <div class="xz_img">
            <img src="../imgs/4/4-2.png" alt="" class="b">
            <img src="../imgs/4/4-3.png" alt="" class="c">
          </div>
          <div class="text">
            <p>工序把控</p>
            <span>掘进、仰拱、二衬等工序数据化<br/>更快找到关键点并调整</span>
          </div>
        </li>
      </ul>
    </div>
    <!-- 超过6000家企业 宣传 -->
    <div class="Publicity">
      <h3>超过6000+家企业组织正在使用</h3>
      <div class="text">鹭涛科技聚焦产业互联网，采用新一代信息技术打造“产业互联网应用引擎”，以B2B、B2C的业务模式，赋能智慧工程、公共服务、数字城市、工程互联网等行业 <br/>助力产业创新与升级，创造新的客户价值，提升客户体验，帮助中国企业进入智能移动办公时代。</div>
      <div class="nuber">
        <div>
          <p>88<span>%</span></p>
          <span>提高项目完成率</span>
        </div>
        <div>
          <p>30<span>%</span></p>
          <span>提供工程进度准确率</span>
        </div>
        <div>
          <p>3000+<span>家</span></p>
          <span>参与智慧工程云平台的企业</span>
        </div>
      </div>
    </div>
    <!-- 产品解决方案 -->
    <div class="Solution">
      <img src="../imgs/cpjjfa.png" alt="鹭涛的解决方案" class="title_img">
      <div class="pentagon"> 
        <div class="xz_img a" @mouseover="mouseOver(1)"  :class="[img_i==1?'xzas':'']">
          智能工程<br/>进度监测
        </div>
        <div class="xz_img b" @mouseover="mouseOver(2)" :class="[img_i==2?'xzas':'']">
          远程查看<br/>人员定位
        </div>
        <div class="xz_img c" @mouseover="mouseOver(3)" :class="[img_i==3?'xzas':'']">
          多项目<br/>同时管理
        </div>
        <div class="xz_img d" @mouseover="mouseOver(4)" :class="[img_i==4?'xzas':'']">
          工程表格<br/>导出
        </div>
        <div class="xz_img e" @mouseover="mouseOver(5)" :class="[img_i==5?'xzas':'']">
          现场状况<br/>实时查看
        </div>
        <div class="xz_img f" @mouseover="mouseOver(6)" :class="[img_i==6?'xzas':'']">
          施工汇报<br/>安全管控
        </div>
          <p class="centent">
             <transition name="el-fade-in-linear">
               <img src="../imgs/xmgl.png" alt="鹭涛进度监控" v-show="img_i==1">
            </transition>
            <transition name="el-fade-in-linear">
               <img src="../imgs/LED_gps.jpg" alt="鹭涛人员定位" v-show="img_i==2" style="width:379px;height:403px;transform: translateX(-25%);"> 
            </transition>
            <transition name="el-fade-in-linear">
              <img src="../imgs/zngcjdjk.png" alt="鹭涛项目管理" v-show="img_i==3">
            </transition>
            <transition name="el-fade-in-linear">
              <img src="../imgs/gcbbdc.png" alt="鹭涛工程报表导出" v-show="img_i==4">
            </transition>
            <transition name="el-fade-in-linear">
              <img src="../imgs/dxmtsgl.png" alt="鹭涛移动端" v-show="img_i==5">
            </transition>
            <transition name="el-fade-in-linear">
              <img src="../imgs/aqgk.png" alt="鹭涛安全管控" v-show="img_i==6">
            </transition>
          </p>
      </div>
    </div>
    <!-- 业务领域 -->
    <div class="Business">
      <img src="../imgs/ywly.png" alt="鹭涛的业务领域" class="title_img">
      <div class="project">
        <ul>
          <li @click="go_xq(0)" style="width:35%"><img src="../imgs/project.png" alt="隧道">中铁八局承建的西武陵隧道</li>
          <li @click="go_xq(8)"><img src="../imgs/project.png" alt="隧道">老挝孟村二号隧道</li>
          <li @click="go_xq(3)" style="width:32%"><img src="../imgs/project.png" alt="隧道">叙毕斑竹林斜井</li>
          <li @click="go_xq(7)" style="width:35%"><img src="../imgs/project.png" alt="隧道">昌景黄陶家岭斜井</li>
          <li @click="go_xq(2)"><img src="../imgs/project.png" alt="隧道">昌景黄霭山隧道</li>
          <li @click="go_xq(5)" style="width:32%"><img src="../imgs/project.png" alt="隧道">中铁八局承建的扬中隧道</li>
        </ul>
      </div>
      <!-- 查看更多 -->
      <div class="ckgd" @click="go_a">
        查看更多 >
      </div>
      <!-- 中国地图 -->
      <img src="../imgs/ditu.png" alt="鹭涛业务地图" class="map">
    </div>
    <!-- 关于我们 -->
    <div class="About">
      <img src="../imgs/gywm.png" alt="关于鹭涛" class="title_img">
      <img src="../imgs/jzt.png" alt="鹭涛" class="jzt">
      <div class="box">
      <div class="bgc"> </div>
      <div class="text">
      <span>
          四川鹭涛智控科技有限公司是一家专注于机械、环保等大型工程信息支持的高新技术企业。公司由电子科技大学、四川大学、澳门大学等多位信息技术专家和大型工程等专业人士组成。公司设置总经办、销售部、财务部、研发部、人力资源部等，目前在职员工人70人。
      </span>
      <span>
        公司资本实力雄厚，经营特色鲜明，主要为工程建设单位提供工程信息服务及其他多元化的信息化建设。通过互通互联、大数据等技术手段，构建工程联网，保证工程系统信息数据的标准统一和全天候不间断数据传输的服务保障。并采用国际先进的架构技术，完成了核心建设的方案设计，并按照“云计算”技术及应用模式，进行系统软件和应用软件、服务器、存储及骨干网、无线网等的科学合理配置... 
      </span>      
      </div>
      </div>
    </div>
    <!-- 合作共赢 -->
    <div class="cooperation">与我们一起合作共赢，建立更好的平台!</div>
  </div>
</template>

<script>
export default {
    metaInfo() {
      return {
        title: '四川鹭涛智控科技',
        meta: [
          { vmid: 'description', name: 'description', content: '四川鹭涛智控科技有限公司是一家专注于机械、环保等大型工程信息支持的高新技术企业。公司由电子科技大学、四川大学、澳门大学等多位信息技术专家和大型工程等专业人士组成。公司设置总经办、销售部、财务部、研发部、人力资源部等，目前在职员工人70人' },
        ],
      };
    },
    data(){
        return{
          img_i:1,
          autoHeight:'600'
        }
    },
    methods:{
      mouseOver(i){
        this.img_i=i
      },
      go_a(){
        this.$router.push('/success');
        this.$emit('childFn', '/success');
      },
      go_xq(i){
        this.$router.push({path:'/cases',query:{userid:i}})
        this.$emit('childFn', '/cases')
      },
      autoHeighta() {
        let _w =document.documentElement.clientWidth || document.body.clientWidth;
        this.autoHeight=(_w * 188) / 600 + "px";
      }
    },
    beforeMount(){
      this.autoHeighta()
    }
}
</script>

<style lang="less" scoped>
// 轮播图
.block{
  width: 100%;
  img{
    width: 100%;
    height: 100%;
  }
}
// 我们的优势
.advantage{
  width: 100%;
  height: 723px;
  .title_img{
    width: 520px;
    height: 102px;
  }
  ul{
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    margin-top: 98px;
    li{
      position: relative;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 25%;
      .text{
        width: 100%;
      }
      img{
        width: 169px;
        height: 169px;
        transition: all 1.3s;
      }
      img:hover{
        // transform:rotate(360deg);
        cursor:pointer;
      }
      .gd{
        position: absolute;
        width: 169px;
        height: 169px;
        margin: 0;
        .a{
          width: auto;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      .xz_img{
        position: relative;
        width: 169px;
        height: 169px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          position: absolute;
          width: auto;
          height: auto;
        }
      }
      .xz_img:hover{
        cursor:pointer;
        .b{
          transform:rotate(360deg);
        }
        .c{
          transform:rotate(-360deg);
        }
      }
      
      div{
        margin-top: 20px;
        p{
          font-size: 30px;
        }
        span{
          font-size: 20px;
          margin-top: 10px;
          display: inline-block;
          color: #94B1D0;
        }
      }
    }
  }
}
// 标题图片公共杨思
.title_img{
  margin-top: 150px;
}
// 超过6000家企业
.Publicity{
  width: 100%;
  height: 400px;
  background: url(../imgs/6000.png) no-repeat;
  background-size: 100% 100%;
  padding-top: 33px;
  position: relative;
  h3{
    font-size: 42px;
  }
  .text{
    font-size: 24px;
    color: #C1C1C1;
    margin-top: 30px;
    line-height: 35px;
  }
  .nuber{
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    // margin-top: 66px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    div{
      p{
        font-size: 80px;
        color: #BA002C;
        line-height: 20px;
        span{
          font-size: 30px;
          color: #C1C1C1;
        }
      }
      span{
        font-size: 24px;
        color: #D0D0D0;
        display: inline-block;
        margin-top: 19px;
      }
    }
  }
}
// 产品解决方案
.Solution{
  width: 100%;
  height: 1323px;
  .title_img{
    width: 641px;
    height: 100px;
    margin-bottom: 212px;
  }
  .pentagon{
    width: 974px;
    height: 747px;
    background: url(../imgs/bk_a.png) no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    .xz_img{
      width: 286px;
      height: 228px;
      background: url(../imgs/mrzt.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      font-size:26px;
      color: #80A2CA;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 48px;
      transition: all .2s;
    }
    .xzas{
      width: 286px;
      height: 228px;
      background: url(../imgs/xzzt.png) no-repeat;
      background-size: 100% 100%;
      color: #FFFFFF;
      font-size:30px;
    }
    div:hover{
      width: 286px;
      height: 228px;
      cursor:pointer;
      background: url(../imgs/xzzt.png) no-repeat;
      background-size: 100% 100%;
      color: #FFFFFF;
      font-size:30px;
    }
    .a{
      top: -114px;
      left: 98px;
    }
    .b{
      top: -114px;
      right: 98px;
    }
    .c{
      top: 260px;
      left: -143px;
    }
    .d{
      top: 260px;
      right: -143px;
    }
    .e{
      bottom: -114px;
      left: 98px;
    }
    .f{
      bottom: -114px;
      right: 98px;
    }
    // 中间图
    .centent{
      padding-top: 118px;
      position: relative;
      width: 200px;
      height: 400px;
      left: 50%;
      transform: translateX(-50%);
      img{
        width: 200px;
        height: 400px;
        position: absolute;
        left: 0;
      }
    }
  }
}
// 业务领域
.Business{
  .title_img{
    width: 468px;
    height: 100px;
  }
  .project{
    margin: 0 auto;
    margin-top: 100px;
    width: 60%;
    min-width: 1200px;
    img{
          width: 58px;
          height: 61px;
          margin-right: 10px;
        }
    ul{
      font-size: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap:wrap
    }
    li{
        display: flex;
        align-items: center;
        width: 30%;
      }
    li:hover{
      cursor:pointer;
    }
  }
  .ckgd{
    margin: 0 auto;
    width: 222px;
    height: 53px;
    border: 2px solid #00FFFF;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 97px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ckgd:hover{
    cursor:pointer;
  }
  .map{
    display: block;
    margin: 0 auto;
    width: 1064px;
    height: 1337px;
  }
}
// 关于我们
.About{
  margin-top: -296px;
  .title_img{
    width: 468px;
    height: 100px;
    margin: 0;
  }
  .jzt{
    width: 966px;
    height: 541px;
    margin: 0 auto;
    margin-top: 100px;
    display: block;
  }
  .box{
    width: 1200px;
    height: 516px;
    font-size: 26px;
    line-height: 50px;
    margin: 0 auto;
    position: relative;
    margin-top: -181px;
    .bgc{
      position: absolute;
      top: 0;
      width: 1200px;
      height: 516px;
      background-color: #132880;
      opacity: 0.58;
    }
    .text{
      width: 1200px;
      height: 516px;
      top: 0;
      position: absolute;
      padding: 65px 25px;
      z-index: 99;
      span{
        // letter-spacing:1px;
        text-indent:2em;
        display: block;
        text-align: left;
      }
    }
  }
  
}
// 合作共赢
.cooperation{
  margin: 0 auto;
  margin-top: 158px;
  font-size: 50px;
  color: #33D5FE;
  //  opacity: 0.58;
}
</style>

<style lang="less">
.el-carousel__indicator,.el-carousel__indicator--horizontal{
  width: 20.8px;
  height: 20.8px;
  display: block;
  margin: 0 10px;
  .el-carousel__button{
    width: 20.8px;
    height: 20.8px;
    border-radius: 50%;
  }
}
.el-carousel__indicators{
  display: flex;
  align-items: center;
  bottom: 40px!important;
}
.el-carousel__arrow{
  width: 60px!important;
  height: 60px!important;
  background-color: rgba(255, 255, 255, 0.3)!important;
  border-radius: 50%!important;
  i{
    font-size: 31px;
  }
}
.el-carousel__arrow--left{
  left: 100px!important;
}
.el-carousel__arrow--right{
  right: 100px!important;
}
</style>