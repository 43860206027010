<template>
  <div class="box sadlad">
    <!-- 产品 -->
    <div class="banner">
      <img src="../imgs/product/banner.png" alt="鹭涛产品" class="banner">
    </div>
    <div class="box_max">
      <!-- 产品目录 -->
      <div class="catalog">
        <div class="left">
          <div class="title">
            <span>PRODUCTS</span>
            <p>产品目录</p>
          </div>
          <div class="APP">
            <p class="a" @click="mouseOver(1)"  :class="[img_i==1?'xzas':'']">隧道通APP</p>
            <p @click="mouseOver(2)"  :class="[img_i==2?'xzas':'']">设备云APP</p>
          </div>
        </div>
        <div class="right">
          <div class="box"></div>
          <div class="a" v-show="img_i==1">隧道通是本公司基于隧道工程的安全管理、工程管理、现场管理等操作需要而进行开发的一个移动端智能产品，拥有便捷化、数据化、智能化等优势，可以帮助工程建筑类企业更好的实施项目。</div>
          <div class="a" v-show="img_i==2">鹭涛二手机械交易网提供挖掘机、推土机、铣刨机、平地机等各种工程机械出租、机械租赁服务，鹭涛二手机械交易网您身边最靠谱的机械租赁机械出租平台！</div>
        </div>
      </div>
      <!-- 隧道通 -->
      <div v-show="img_i==1">
        <!-- 视频展示 -->
        <div class="video_box">
          <img src="../imgs/product/2.jpg" class="left" @click="bf(1)" :class="[video_b==1?'bf':'']">
          <img src="../imgs/product/1.jpg" class="zj" @click="bf(0)" :class="[video_b==1?'left_z':video_b==2?'right_z':'']">
          <div  class="video_a">
            <div class="a">
              <video autoplay controls="controls" :src="video_src" ref="refVideo"  width='314' height='555.4'></video>
            </div>
          </div>
          <img src="../imgs/product/3.jpg" class="right" @click="bf(2)" :class="[video_b==2?'bf':'']">
        </div>
      <!-- 图片集 -->
        <div class="img_collection">
          <div v-for="(item,i) in list" :key='i'>
            <div class="text top" v-if="(i%2!=0)">
              <p>{{item.title}}</p>
              <span>{{item.text}}</span>
            </div>
            <img :src="item.url" >
            <div class="text bottom" v-if="(i%2==0)">
              <p>{{item.title}}</p>
              <span>{{item.text}}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 机械管家 -->
      <div v-show="img_i==2">
        <!-- 大图展示 -->
        <div class="img_max">
          <div class="left">
            <img src="../imgs/product/A1.png" alt="">
          </div>
          <div class="right">
            <p>实施管理软件系统，可合理降低维修保养成本及相关损耗，降低整体运营成本，提高经济效益。</p>
            <p class="bottom">机械云管家专注于为用户解决在机械设备出售、出租及相关一系列问题时提供的优化解决方案。系统分为首页、设备管理、设备市场、个人中心4 大模块。</p>
          </div>
        </div>
        <!-- 图片集 -->
        <div class="img_collection" style="margin-top:166px">
          <div v-for="(item,i) in listB" :key='i'>
            <div class="text top" v-if="(i%2!=0)">
              <p>{{item.title}}</p>
              <span>{{item.text}}</span>
            </div>
            <img :src="item.url" >
            <div class="text bottom" v-if="(i%2==0)">
              <p>{{item.title}}</p>
              <span>{{item.text}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  metaInfo() {
      return {
        title: '四川鹭涛智控科技产品',
        meta: [
          { vmid: 'description', name: 'description', content: '四川鹭涛智控科技有限公司是一家专注于机械、环保等大型工程信息支持的高新技术企业。公司由电子科技大学、四川大学、澳门大学等多位信息技术专家和大型工程等专业人士组成。公司设置总经办、销售部、财务部、研发部、人力资源部等，目前在职员工人70人' },
        ],
      };
    },
    data(){
        return{
          img_i:1,
          video_b:0,
          video_src:require('../imgs/product/1.ogg'),
          list:[
            {
              title:'智能进度监测',
              url:require('../imgs/product/1.png'),
              text:'鹭涛智控工程管理系统能帮助我们更高效的管理多项目管理、工程进度管理、现场管理、工序衔接、数据汇总。'
            },
            {
              title:'施工安全汇报',
              url:require('../imgs/product/2.png'),
              text:'提供两台阶、CD、CRD等多种开挖法的沉降观测上报，把控施工的安全监控环节。达到预设沉降阈值提示警报。'
            },
            {
              title:'自动生成数据',
              url:require('../imgs/product/3.png'),
              text:'管理者可自定义时间、项目、报表类型等自动生成表格。云端存储安全，何时何地皆能调取查看、分享打印、汇总归档。'
            },
            {
              title:'查看监控视频',
              url:require('../imgs/product/4.png'),
              text:'隧道内摄像头与云端服务器连接，在任何有网络的地方查看实时洞内施工情况，可以更快捷高效处置隧道内的事宜。'
            },
          ],
          listB:[
            {
              title:'首页',
              url:require('../imgs/product/A2.png'),
              text:'涵盖了入住用户的消息展示、新闻窗口、买卖设备入口以及软件的功能介绍。'
            },
            {
              title:'设备市场',
              url:require('../imgs/product/A3.png'),
              text:'设备市场页面可进行设备出租、出售、货运、招聘、发布以及估价等操作。精选消息内容区展示了设备售卖相关的优质信息。'
            },
            {
              title:'设备管理',
              url:require('../imgs/product/A4.png'),
              text:'设备管理页是软件的重要功能操作入口的页面展示，包括有消息提示、设备列表、设备管理、设备租赁、协同办公5大模块。'
            },
            {
              title:'个人中心',
              url:require('../imgs/product/A5.png'),
              text:'个人中心展示了用户的基本信息，并提供了关于应用软件的一些相关操作，包括有软件介绍、软件更新以及退出登录等。'
            },
          ]
        }
    },
    methods:{
      mouseOver(i){
        this.img_i=i
      },
      bf(i){
        this.video_b=i
        let thta=this
        // 中间视频
        if (i==0) {
          setTimeout(function(){ thta.video_src=require('../imgs/product/1.ogg') }, 500);
        }else if (i==1) { //左边视频
          setTimeout(function(){ thta.video_src=require('../imgs/product/2.ogg') }, 500);
        }else if (i==2) { //右边视频
          setTimeout(function(){ thta.video_src=require('../imgs/product/3.ogg') }, 500);
        }
      }
    }
}
</script>

<style lang="less" scoped>
.box_max{
  width: 1200px;
  margin: 0 auto;
}
// 产品目录
.catalog{
  margin-top: 149px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left{
    height: 452px;
    .title{
      text-align: left;
      span{
        font-size: 24px;
        color: #1667A8;
        line-height: 18px;
      }
      p{
        font-size: 48px;
        font-weight: 500;
        line-height: 48px;
        margin-top: 22px;
      }
    }
    .APP{
      p{
        height: 117px;
        width: 600px;
        border-bottom:  2px solid #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        font-weight: 600;
        color: #999999;
      }
      p:hover{
        cursor:pointer;
      }
      .a{
        height: 73px;
        margin-top: 70px;
      }
      .xzas{
        color: #00E7FE;
      }
    }
  }
  .right{
    width: 564px;
    height: 408px;
    background: url(../imgs/product/dt.png) no-repeat;
    background-size: 100%;
    position: relative;
    div{
      width: 469px;
      height: 328px;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    .box{
      background: #1667A8;
      opacity: 0.4;
      position: absolute;
    }
    .a{
      position: absolute;
      font-size: 24px;
      line-height: 42px;
      padding: 0 20px;
      padding-top: 56px;
      text-align: left;
    }
  }
}
// 视频展示
.video_box{
  width: 100%;
  height: 705px;
  background: url(../imgs/product/xz.png) no-repeat;
  background-size: 100%;
  margin-top: 117px;
  position: relative;
  // 视频图片公用格式
  img{
    width: 246px;
    height: 437px;
    position: absolute;
    top: 176px;
    transition: all .5s;
    z-index: 1;
  }
  // 中间图片
  .zj{
    width: 314px;
    height: 555.4px;
    top: 141px;
    left: 50%;
    transform: translateX(-51.5%);
  }
  // 中间切换到左边
  .left_z{
    width: 246px;
    height: 437px;
    top: 176px;
    left: 229px;
  }
  // 中间切换到右边
  .right_z{
    width: 246px;
    height: 437px;
    top: 176px;
    transform: none;
    left: 842px;
  }
  // 左边图片
  .left{
    left: 112px;
  }
  // 右边图片
  .right{
    right: 112px;
  }
  // 左右选中之后的样式  播放
  .bf{
    width: 314px;
    height: 555.4px;
    top: 141px;
    left: 50%;
    transform: translateX(-51.5%);
  }
  // 播放器容器
  .video_a{
    width: 570px;
    height: 762px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 54px;
    background: url(../imgs/product/sj.png) no-repeat;
    background-size: 520px 100%;
    background-position-x: 62%;
    z-index: 99;
    .a{
      position: absolute;
      width: 314px;
      height: 555.4px;
      top: 87px;
      left: 51%;
      transform: translateX(-51.5%);
      z-index: 9999;
      video{
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
      }
    }
  }
}
// 图片集
.img_collection{
  width: 100%;
  margin-top: 448px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img{
    width: 286px;
    height: 501px;
  }
  div{
    width: 286px;
  }
  .text{
    p{
      margin-bottom: 15px;
      font-size: 24px;
    }
    span{
      font-size: 18px;
      color: #999999;
      text-align: left;
      display: block;
    }
  }
  .top{
    margin-bottom: 21px;
  }
  .bottom{
    margin-top: 21px;
  }
}
// 大图展示
.img_max{
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 161px;
  .left{
    img{
      width: 636px;
      height: 635px;
    }
  }
  .right{
    font-size: 24px;
    color: #FFFFFF;
    width: 430px;
    p{
      text-align: left;
    }
    .bottom{
      margin-top:98px;
    }
  }
}
</style>