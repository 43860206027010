import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
// 首页
import home from '../views/home.vue'
// 产品
import product from '../views/product.vue'
// 硬件系统
import Hardware from '../views/Hardware.vue'
// 成功案列
import success from '../views/success.vue'
// 软件下载
import Software from '../views/Software.vue'
// 登陆系统
import land from '../views/land.vue'
// 成功案列详情
import cases from '../views/cases/cases.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    children: [
      {
        path: "/",
        name: "首页",
        component: home
      },
      {
        path: "/product",
        name: "产品",
        component: product
      },
      {
        path: "/Hardware",
        name: "硬件系统",
        component: Hardware
      },
      {
        path: "/success",
        name: "成功案列",
        component: success
      },
      {
        path: "/Software",
        name: "软件下载",
        component: Software
      },
      {
        path: "/land",
        name: "登陆系统",
        component: land
      },
      {
        path: "/cases",
        name: "成功案列详情",
        component: cases
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

export default router
