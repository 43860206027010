<template>
  <!-- 硬件系统 -->
  <div class="box">
    <div class="banner">
      <img src="../imgs/yjxt/banner_cp.png" alt="鹭涛产品" class="banner">
    </div>
    <div class="m_box">
      <!-- 人车分流 -->
      <div class="shunt">
        <img src="../imgs/yjxt/rcfl.png" alt="鹭涛人车分流系统" class="title_img">
        <div class="text">
          人车分流系统是由车辆道闸、人员道闸、人员通道雨棚、封闭围栏、专用电脑控件等部件组成。针对施工现场车辆、人员流动性强，进出频繁，难以管理的特点，该系统实行人车分流策略。
        </div>
        <!-- 人员道闸 -->
        <div class="introduce">
          <div class="bt_img">
            <img src="../imgs/yjxt/rydz.png" alt="人员道闸">
            <p>
              该系统中现场工作人员等需通过人员道闸才能进入到隧道内，人走人道，车行车道，互不相扰，同时还能满足工作人员的安全保障。
            </p>
          </div>
          <div class="tu">
            <img src="../imgs/yjxt/rydz_t.png" alt="人员道闸">
          </div>
        </div>
        <!-- 车辆道闸 -->
        <div class="introduce">
          <div class="tu">
            <img src="../imgs/yjxt/cldz_t.png" alt="车辆道闸" style="margin-top:61px">
          </div>
          <div class="bt_img">
            <div class="right"><img src="../imgs/yjxt/cldz.png" alt="车辆道闸" ></div>
            <p>
              车行车道，行人与车辆通道完全隔离，实现人车分流，进洞车辆一目了然，洞口管理井然有序，安全保障品质升级。
            </p>
          </div>
        </div>
        <!-- 人脸识别 -->
        <div class="introduce">
          <div class="bt_img" style="padding-top:59px;">
            <img src="../imgs/yjxt/rlsb.png" alt="人脸识别">
            <p>
              人脸识别机制限制人员的进出，保障禁止非工作人员私自进出施工区域。
            </p>
          </div>
          <div class="tu">
            <img src="../imgs/yjxt/rlsb_t.png" alt="人脸识别">
          </div>
        </div>
      </div>
      <!-- 可视化现场管理 -->
      <div class="visualization">
        <img src="../imgs/yjxt/visualization.png" alt="鹭涛可视化现场管理" class="title_img">
        <div class="text">
          视频监控系统包括前端视频采集设备、通讯传输设备，以及后端的视频云服务器。该系统具有实时存储、回放、检索、查询功能。
        </div>
        <ul>
          <li>
            <div>
              <img src="../imgs/yjxt/fbsxt.png" alt="鹭涛防爆摄像头">
            </div>
            <p>200W防爆球机摄像头</p>
          </li>
          <li>
            <div>
              <img src="../imgs/yjxt/bridge.png" alt="鹭涛防爆摄像头">
            </div>
            <p>三公里无线传输网桥</p>
          </li>
          <li>
            <div>
               <img src="../imgs/yjxt/machine.png" alt="鹭涛防爆摄像头">
            </div>
            <p>400W枪机摄像头</p>
          </li>
          <li>
            <div>
              <img src="../imgs/yjxt/definition.png" alt="鹭涛防爆摄像头">
            </div>
            <p>200W高清球机摄像头</p>
          </li>
          <li>
            <div>
              <img src="../imgs/yjxt/video.png" alt="鹭涛防爆摄像头">
            </div>
            <p>视频存储器</p>
          </li>
        </ul>
      </div>
      <!-- 高清室外LED -->
      <div class="LED">
        <img src="../imgs/yjxt/LED.png" alt="隧道应急电话" class="title_img">
         <div class="text">
          根据现场实际情况，在洞口安装室外单色屏或高清LED彩屏，可根据需要显示人员考勤、人员实时定位展示、气体监测数据、施工进度、播放
        </div>
      </div>
      <!-- 人员精准定位 -->
      <div class="gps">
        <img src="../imgs/yjxt/gps.png" alt="人员定位" class="title_img">
         <div class="text">
          人员定位采用的 UWB 定位技术为目前国内先进的无线定位技术方案。可以实现分米级的高精度测距、定位，是传统米级定位无法达到的
        </div>
      </div>
      <!-- 隧道应急电话 -->
      <div class="Telephone">
        <img src="../imgs/yjxt/Telephone.png" alt="人员定位" class="title_img">
         <div class="text">
          在建隧道的特点是现场声音嘈杂，随着开挖长度的深入移动信号也在减弱或无信号。通常情况下当环境噪音超过90分贝时，普通电话机的铃声经常不能让工作人员听到铃声，导致生产调度指令得不到及时地传达，造成经济损失。
        </div>
        <div class="text" style="margin-top:44px;">
          该系统专门针对各类隧道高潮湿的环境设计，具有高防护、低功耗、防尘防潮、抗噪能力强、声音清晰洪亮的特点。该系统的话机经过若干次铃响后（默认5次），话机自动接听，主叫方可进行呼叫广播，其声音将通过外置扬声器广播给附近的施工人员。
        </div>
        <ul>
          <li>
            <div>
              <img src="../imgs/yjxt/yjdh.png" alt="鹭涛防爆摄像头">
            </div>
            <p>隧道应急电话</p>
          </li>
          <li>
            <div>
              <img src="../imgs/yjxt/gbht.png" alt="鹭涛防爆摄像头">
            </div>
            <p>网络广播话筒</p>
          </li>
          <li>
            <div>
               <img src="../imgs/yjxt/zbsdh.png" alt="鹭涛防爆摄像头">
            </div>
            <p>值班室电话</p>
          </li>
          <li>
            <div>
              <img src="../imgs/yjxt/dhfwq.png" alt="鹭涛防爆摄像头">
            </div>
            <p>网络电话服务区</p>
          </li>
          <li>
            <div>
              <img src="../imgs/yjxt/yx.png" alt="鹭涛防爆摄像头">
            </div>
            <p>网络音柱</p>
          </li>
        </ul>
      </div>
      <!-- 有害气体检测 -->
      <div class="gas">
        <img src="../imgs/yjxt/gas_title.png" alt="有害气体检测" class="title_img">
          <div class="text">
          根据项目隧道设计图具体状况，提供一氧化碳、二氧化硫、氮氧化物、瓦斯等有毒有害、易燃易爆气体的监测和预警。
          </div>
          <div class="text" style="margin-top:50px;">
            将监测设备安装在防水挂布台车位置离前方工作面更近，对施工环境气体变化更快监测。在高浓度瓦斯的隧道，还可以用馈电装置与防爆控制箱
          </div>
          <ul>
            <li>
              <img src="../imgs/yjxt/gas_1.png" alt="有害气体检测">
            </li>
            <li>
              <img src="../imgs/yjxt/gas_2.png" alt="有害气体检测">
            </li>
            <li>
              <img src="../imgs/yjxt/gas_3.png" alt="有害气体检测">
            </li>
            <li>
              <img src="../imgs/yjxt/gas_4.png" alt="有害气体检测">
            </li>
            <li>
              <img src="../imgs/yjxt/gas_5.png" alt="有害气体检测">
            </li>
          </ul>
      </div>
      <!-- VR 体验 -->
      <div class="vr">
        <img src="../imgs/yjxt/vr.png" alt="vr体验馆" class="title_img">
         <div class="text">
          VR安全体验馆提供工程上各种安全措施体验，软件根据客户实际需求个性化选择（包含隧道逃生、高空坠落、交通事故、机械伤害、设备违操、作业触电等），可以更直观的对施工人员进行安全教育。
        </div>
      </div>
      <!-- 房建 -->
      <div class="vr">
        <img src="../imgs/yjxt/fjian.png" alt="房建" class="title_img">
         <div class="text">
          房间方面我们提供扬尘监控、塔吊安控系统等一系列完备的解决方案。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
      return {
        title: '四川鹭涛智控科技硬件',
        meta: [
          { vmid: 'description', name: 'description', content: '四川鹭涛智控科技有限公司是一家专注于机械、环保等大型工程信息支持的高新技术企业。公司由电子科技大学、四川大学、澳门大学等多位信息技术专家和大型工程等专业人士组成。公司设置总经办、销售部、财务部、研发部、人力资源部等，目前在职员工人70人' },
        ],
      };
    },
    data(){
        return{

        }
    }
}
</script>

<style lang="less" scoped>
.title_img{
  margin-top: 150px;
}
.m_box{
  width: 1200px;
  margin: 0 auto;
}
.banner{
  width: 100%;
}
// 人车分流
.shunt{
  width: 100%;
  .title_img{
    margin-top: 150px;
    width: 810px;
    height: 100px;
    margin-bottom: 78px;
  }
  .text{
    font-size: 24px;
    line-height: 42px;
    width: 1200px;
    margin: 0 auto;
    text-indent:2em;
    text-align: left;
    margin-bottom: 94px;
  }
  .introduce{
    margin-top: 66px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    // 标题加文字
    .bt_img{
      text-align: left;
      img{
        width: 159px;
        height: 43px;
        margin-top: 8px;
        margin-bottom: 31px;
      }
      .right{
        text-align: right;
      }
      p{
        font-size: 24px;
        line-height: 42px;
        width: 554px;
      }
    }
  }
}
.text{
  font-size: 24px;
  line-height: 42px;
  width: 1100px;
  margin: 0 auto;
  text-indent:2em;
  text-align: left;
  margin-top: 78px;
}
// 可视化现场管理
.visualization{
  .title_img{
    margin-top: 118px;
  }
  .text{
      font-size: 24px;
      line-height: 42px;
      width: 1100px;
      margin: 0 auto;
      text-indent:2em;
      text-align: left;
      margin-bottom: 82px;
      margin-top:78px;
  }
  ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    li{
      height: 330px;
      display: flex;
      align-items: center;
      flex-direction:column;
      justify-content: space-between;
      div{
        height: 226px;
        display: flex;
        align-items: center;
      }
      p{
        font-size: 18px;
        color: #d6d6d6;
        border: 1px solid #00FFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 190px;
        height: 45px;
      }
    }
  }
}
// 隧道应急电话
.Telephone{
  ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
    li{
      height: 330px;
      display: flex;
      align-items: center;
      flex-direction:column;
      justify-content: space-between;
      div{
        height: 201px;
        display: flex;
        align-items: center;
      }
      p{
        font-size: 18px;
        color: #d6d6d6;
        border: 1px solid #00FFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 190px;
        height: 45px;
      }
    }
  }
}
// 有害气体检测
.gas{
  ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 74px;
  }
}
</style>