<template>
    <div class="box sadlad">
        <!-- 成功案列详情 -->
        <div class="banner">
            <img src="../../imgs/cgal/banner.png" alt="鹭涛产品" class="banner">
        </div>
        <div class="img_t">
            <img :src="list[aL].img_url" alt="">
            <span  :class="[aL==0?'':aL==1||aL==2||aL==3||aL==4?'qi':aL==6||aL==7||aL==8||aL==9?'ba':'liu']"></span>
            <p>{{list[aL].title}}</p>
        </div>
        <div class="box_m">
            <div class="a">
                <div class="title">{{list[aL].title_a}}</div>
                <div class="text">
                    {{list[aL].text}}
                </div>
            </div>
            <div class="b">
                {{list[aL].text_b}}
                <br><br/>
                {{list[aL].text_c}}
            </div>
            <div class="img_list">
            <p>施工进程图</p>
            <div>
                <img :src="item" alt="" v-for="(item,i) in list[aL].imgs" :key="i">
            </div>
        </div>
        </div>
        <div class="right_i">
            <div>
                 <p>施工相关情况</p>
                <span></span>
            </div>
            <img :src="list[aL].right_img"  >
        </div>
        <div class="Small">
        <ul>
            <li>
                <div class="title">联系我们</div>
                <span>联系电话：400-990-8082</span>
                <span>电子邮箱：sc_lutao@163.com</span>
                <span>地址：成都市高新区中海海科大厦</span>
            </li>
            <li>
                <div class="title">关于鹭涛</div>
                <span>公司介绍</span>
                <span @click="yj">硬件设备</span>
                <span @click="al">成功案例</span>
            </li>
            <li class="ewm">
                <img src="../../imgs/ewm.png" alt="鹭涛小程序">
                <div>扫码使用微信小程序</div>
            </li>
        </ul>
        <div class="bah">
            Copyright © 2015 - 2018四川鹭涛智控科技有限公司All Rights Reserved <a href="https://beian.miit.gov.cn">蜀ICP备2020034987号-1</a>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    metaInfo() {
      return {
        title: '鹭涛智控成功案列',
        meta: [
          { vmid: 'description', name: 'description', content: '四川鹭涛智控科技有限公司是一家专注于机械、环保等大型工程信息支持的高新技术企业。公司由电子科技大学、四川大学、澳门大学等多位信息技术专家和大型工程等专业人士组成。公司设置总经办、销售部、财务部、研发部、人力资源部等，目前在职员工人70人' },
        ],
      };
    },
    data(){
        return{
            aL:0,
            list:[
                {
                    img_url:require('../../imgs/cgal/1.png'),
                    title:'西武陵隧道石山横洞',
                    title_a:'昌景黄铁路西武岭隧道石山横洞顺利贯通',
                    text:'据悉，西武岭隧道位于安徽省黄山市黟县与祁门县交界处，全长12623.8米，为单洞双线隧道，采用机械化施工，是昌景黄铁路全线重点控制性工程。隧道设置4座辅助坑道，其中，石山横洞全长99米，采用无轨双车道运输方式进行施工，承担1465.8米的正洞施工任务',
                    text_b:'6月10日，由二公司昌景黄二分部负责施工的西武岭隧道石山横洞顺利贯通。该横洞是西武岭隧道首座贯通的辅助坑道，标志着西武岭隧道正式进入正洞施工阶段。',
                    text_c:'自石山横洞进洞以来，昌景黄二分部多措并举，保障施工进度。一是持续加强与地方政府的沟通协调 ，避免因征拆等问题影响施工进度；二是持续加强安全质量把控，轮班作业施工，狠抓过程控制，确保项目管理人员24小时值守现场；三是持续加强环水保措施，石山横洞临近地下水源地漳水河，通过编制环水保专项施工方案，建立雨污分离系统，落实“建生态环保高铁”理念。',
                    right_img:require('../../imgs/cases/sshd/bg.png'),
                    imgs:[require('../../imgs/cases/sshd/1.png'),require('../../imgs/cases/sshd/2.png'),require('../../imgs/cases/sshd/3.png'),require('../../imgs/cases/sshd/4.png'),require('../../imgs/cases/sshd/5.png'),require('../../imgs/cases/sshd/6.png'),require('../../imgs/cases/sshd/7.png'),require('../../imgs/cases/sshd/8.png'),require('../../imgs/cases/sshd/9.png'),]
                },
                {
                    img_url:require('../../imgs/cgal/2.png'),
                    title:'昌景黄新庄横洞',
                    title_a:'昌景黄铁路西武岭隧道新庄横洞顺利贯通 ',
                    text:'9月7日，由中铁八局一公司承建的昌景黄铁路西武岭隧道新庄横洞顺利贯通，标志着西武岭隧道正式进入正洞施工阶段。',
                    text_b:'该横洞是保证西武岭隧道工期节点的重要辅助坑道。自2018年12月进场以来，中铁八局一公司昌景黄一分部不等不靠，积极与地方政府沟通协调，加快征拆进度，克服了施工组织难度大、风险高、山区雨水多、环保要求高等难题，积极为施工创造有利条件。同时，在施工过程中，昌景黄一分部以打造“生态环保智能高铁”为目标，严把质量安全关，狠抓现场管控，全天轮班作业，落实环水保措施，确保施工有序进行。',
                    text_c:'据悉，由中铁八局一公司承建的西武岭隧道出口段长4720米，最大埋深515米，最小埋深5米，是昌景黄铁路全线重点控制性工程。其中，新庄横洞为该隧道的辅助坑道，用作进入正洞施工的坑道及应急救援通道，位于线路左侧，全长417米，按双车道布置，采用翼墙式洞门。',
                    right_img:require('../../imgs/cases/2/bg.png'),
                    imgs:[require('../../imgs/cases/2/1.png'),require('../../imgs/cases/2/2.png'),require('../../imgs/cases/2/3.png'),require('../../imgs/cases/2/4.png'),require('../../imgs/cases/2/5.png'),require('../../imgs/cases/2/6.png'),require('../../imgs/cases/2/7.png'),require('../../imgs/cases/2/8.png'),require('../../imgs/cases/2/9.png'),]
                },
                {
                    img_url:require('../../imgs/cgal/3.png'),
                    title:'昌景黄霭山隧道',
                    title_a:'池黄高铁最新进展 全线首座隧道正式进洞施工 ',
                    text:'中铁上海院5月7日消息：近日，由中铁上海院设计的池黄高铁施工建设迎来重要节点，全线首座隧道——岩山头隧道顺利进洞施工。至此，池黄高铁隧道建设全面拉开帷幕。',
                    text_b:'岩山头隧道大里程方向与昌景黄霭山隧道近距离并行，最小净距约14m，隧道出口段黟县东站渡线进入隧道形成四线隧道，最大开挖面宽22.5m、高约15m，该区域地质复杂，施工难度大。',
                    text_c:'池黄高铁全长约121km，位于安徽省南部，线路两端衔接宁安高铁和杭黄高铁，是国家高铁网武汉至杭州通道的组成部分。池黄高铁沿线旅游资源丰富、文化底蕴深厚、风景名胜汇集，沿线分布有九华山、黄山、西递、宏村、齐云山等蜚声中外的5A级景区及众多4A级景区，是一条名副其实的旅游观光铁路。该线建成后对落实长江经济带和长三角一体化发展战略、完善高铁网布局、促进区域经济社会可持续发展具有重要意义。',
                    right_img:require('../../imgs/cases/ct/3.png'),
                    imgs:[require('../../imgs/cases/2/1.png'),require('../../imgs/cases/2/2.png'),require('../../imgs/cases/2/3.png'),require('../../imgs/cases/2/4.png'),require('../../imgs/cases/2/5.png'),require('../../imgs/cases/2/6.png'),require('../../imgs/cases/2/7.png'),require('../../imgs/cases/2/8.png'),require('../../imgs/cases/2/9.png'),]
                },
                {
                    img_url:require('../../imgs/cgal/4.png'),
                    title:'叙毕斑竹林斜井',
                    title_a:'叙毕铁路川滇段建设顺利推进',
                    text:'据介绍，叙毕铁路（川滇段）位于川滇黔三省接合部，北起既有纳叙铁路的龙凤站，终于滇黔省界，与叙毕线贵州段对接。正线建设长度151.84km（其中四川省境内50.66km），设计时速120km/h，建设工期6年，预计2022年建成通车。',
                    text_b:' 近日，叙毕铁路难度最大的全线控制性工程——斑竹林隧道目前斜井、横洞已贯通，现共有6个工作面同时掘进，开累掘进5896米，完成总长度的46%，基本满足铺架工期要求。叙毕铁路（川滇段）由泸州市兴泸投资集团参与投资建设，自2016年10月开工建设以来，已累计完成投资39.88亿元。',
                    text_c:'全线重、难点工程——长岭隧道、要家山隧道、地木湾特大桥等也按计划推进，安全、质量、工期可控。此外，一般性工程也不断取得实质性进展，三狮坪隧道、观音坡隧道等20座隧道预计将于今年贯通。',
                    right_img:require('../../imgs/cases/ct/4.png'),
                    imgs:[require('../../imgs/cases/2/1.png'),require('../../imgs/cases/2/2.png'),require('../../imgs/cases/2/3.png'),require('../../imgs/cases/2/4.png'),require('../../imgs/cases/2/5.png'),require('../../imgs/cases/2/6.png'),require('../../imgs/cases/2/7.png'),require('../../imgs/cases/2/8.png'),require('../../imgs/cases/2/9.png'),]
                },
                {
                    img_url:require('../../imgs/cgal/5.png'),
                    title:'昌景黄上村隧道',
                    title_a:'昌景黄铁路上村隧道贯通工程过半',
                    text:'昌景黄铁路黟县西递上村隧道工程全面加快建设进度，开挖长度已过半。上村隧道为单洞单线隧道，隧道全长3325.21m，中铁八局负责承建，目前左线开挖完成1085m，右线开挖完成1000m，预计到今年底这条隧道贯通。',
                    text_b:' 据了解，中铁八局昌景黄铁路二分部，共承建隧道9座共计17.8km，桥梁8座2.4km，路基12段994m，无砟轨道铺轨长度30.8km；截止目前，该分部9座隧道共开挖完成4.3km，8座桥梁共计完成19个桥梁承台，14个桥梁墩身及台帽施工，路基土石方开挖完成4段。',
                    text_c:'中铁八局昌景黄铁路二分部作业队队长许顺介绍到，2020年初，新冠疫情对施工影响非常大，铁路二分部在黟县政府的支持和帮扶下，一手抓复工，一手抓防疫，于3月25日全面恢复生产。为了降低疫情对总工期的影响，已组织劳动力1533人。部分工程严格按照24小时工作制度，不分昼夜三班倒施工。',
                    right_img:require('../../imgs/cases/ct/5.png'),
                    imgs:[require('../../imgs/cases/2/1.png'),require('../../imgs/cases/2/2.png'),require('../../imgs/cases/2/3.png'),require('../../imgs/cases/2/4.png'),require('../../imgs/cases/2/5.png'),require('../../imgs/cases/2/6.png'),require('../../imgs/cases/2/7.png'),require('../../imgs/cases/2/8.png'),require('../../imgs/cases/2/9.png'),]
                },
                {
                    img_url:require('../../imgs/cgal/6.png'),
                    title:'贵阳杨中隧道',
                    title_a:'花冠路南段杨中隧道左幅近日顺利贯通',
                    text:'杨中隧道是花冠路项目重要的控制性工程之一，隧道全长200米，设计行车速度为60公里/小时，为双向6车道城市主干道隧道。施工方组织相关专家团队现场踏勘，优化方案，通过“管超前、短进尺、强支护、勤量测”等方式，确保了隧道安全顺利贯通。',
                    text_b:' 据了解，贵阳市花冠路是2018年贵州省重点工程，工期紧，任务重。由中铁隧道集团一处有限公司承建的四标线路全长1290米，包含隧道工程、桥梁工程、路基工程、管线工程、隧道装修工程、管理用房工程等，其中大将山隧道为全线的重点控制性工程。',
                    text_c:'该隧道地质围岩等级差(均为Va级围岩)，围岩以砂岩、泥岩为主，表层风化，其中含少量的中风化含泥质白云岩、中风化白云岩。围岩遇水容易软化，自稳时间短，稳定性差，给施工带来极大困难。为此特组织相关专家团队现场踏勘，对优化方案进行评审论证，通过管超前，短进尺，强支护，勤量测等方式，确保了隧道安全顺利贯通。',
                    right_img:require('../../imgs/cases/ct/6.png'),
                    imgs:[require('../../imgs/cases/2/1.png'),require('../../imgs/cases/2/2.png'),require('../../imgs/cases/2/3.png'),require('../../imgs/cases/2/4.png'),require('../../imgs/cases/2/5.png'),require('../../imgs/cases/2/6.png'),require('../../imgs/cases/2/7.png'),require('../../imgs/cases/2/8.png'),require('../../imgs/cases/2/9.png'),]
                },
                {
                    img_url:require('../../imgs/cgal/7.png'),
                    title:'昌景黄西武陵出口',
                    title_a:'中铁八局昌景黄项目西武岭隧道全线率先进洞施工',
                    text:'西武岭隧道是位于安徽省黄山市黟县与祁门县交界处的单洞双线隧道，全长12623.8米，为昌景黄铁路安徽段最长隧道，具有风险高、工期紧等特点。',
                    text_b:' 昌景黄铁路是构建赣中地区至长三角地区快速客运通道的需要，也是提升既有皖赣铁路运输能力、改善服务质量的需要，还是促进生态文明建设，实现可持续发展的需要。昌景黄高铁预计将在2023年建成通车，通车后，沿线的余干、鄱阳、乐平、景德镇等地区都将结束不通高铁的历史。',
                    text_c:'',
                    right_img:require('../../imgs/cases/ct/7.png'),
                    imgs:[require('../../imgs/cases/2/1.png'),require('../../imgs/cases/2/2.png'),require('../../imgs/cases/2/3.png'),require('../../imgs/cases/2/4.png'),require('../../imgs/cases/2/5.png'),require('../../imgs/cases/2/6.png'),require('../../imgs/cases/2/7.png'),require('../../imgs/cases/2/8.png'),require('../../imgs/cases/2/9.png'),]
                },
                {
                    img_url:require('../../imgs/cgal/8.png'),
                    title:'昌景黄陶家岭斜井',
                    title_a:'昌景黄铁路陶家岭斜井顺利贯通',
                    text:'2019年11月30日，由中铁八局二公司承建的昌景黄铁路西武岭隧道最长辅助坑道陶家岭斜井提前21天贯通，为全线重点控制性工程西武岭隧道的正洞施工奠定了坚实基础。西武岭隧道全长12623米，陶家岭斜井全长1017米，承担正洞施工任务3335米。',
                    text_b:'陶家岭斜井作为全线线下关键线路工程，面临施工时间紧、组织协调难度大、人员调配精度高、环保质量要求严等问题，抢工期、抓进度、控安全、保质量是陶家岭斜井施工任务的重中之重。',
                    text_c:'自进场以来，二公司昌景黄二分部全体员工迅速进入工作状态，立即对现场布置进行规划，制定了周密部署，一是因地制宜设置作业队，扎实高效推进各项进洞准备工作，促成了陶家岭斜井成为昌景黄首个进洞的工点；二是以光面爆破为突破口，以“PDCA循环模式”作为提高隧道光面爆破管理的基本方法，不断调整和优化爆破参数；三是合理优化洞口布置，及时调整与正洞交汇里程，并通过精心组织协调，切实提高施工功效，减少工序搭接，实现了陶家岭斜井连续4个月单月成洞200米以上的施工成果，创全线第一。',
                    right_img:require('../../imgs/cases/ct/8.png'),
                    imgs:[require('../../imgs/cases/2/1.png'),require('../../imgs/cases/2/2.png'),require('../../imgs/cases/2/3.png'),require('../../imgs/cases/2/4.png'),require('../../imgs/cases/2/5.png'),require('../../imgs/cases/2/6.png'),require('../../imgs/cases/2/7.png'),require('../../imgs/cases/2/8.png'),require('../../imgs/cases/2/9.png'),]
                },
                {
                    img_url:require('../../imgs/cgal/9.png'),
                    title:'老挝孟村二号隧道',
                    title_a:'中老铁路孟村二号隧道顺利贯通',
                    text:'2020年1月7日，由中铁八局三公司中老铁路三标二分部负责施工的中老铁路孟村二号隧道顺利贯通。至此，项目部负责施工段内隧道全部贯通。孟村二号隧道的顺利贯通标志着项目部施工里程段进入收尾阶段，保证了中老铁路架梁工期，为线上工程展开奠定了坚实基础。',
                    text_b:'中老铁路孟村二号隧道位于老挝琅勃拉邦省湄公河半岛无人区，全长5890米，三次穿越断层带，地质情况复杂，施工难度极大，为Ⅰ级高风险隧道，是中老铁路线上控制性和重点隧道工程之一。该隧道由于出口地形狭窄，不具备工作面进洞条件，且会宴河车站双线456米布置进入隧道。为克服该施工困难，满足工期要求，在隧道出口设置横洞240米于线路前进方向左侧，通过打通横洞进入正洞施工。',
                    text_c:'为解决该重难点隧道技术攻关，三公司磨万二分部精心策划，不断优化施工技术方案，加强施工技术交底，严格遵守施工规范和标准、质量检查及验收标准，在上百余名中老工人的共同努力下，最快速度推进工程施工进度，实现了孟村二号隧道横洞至出口的顺利贯通，极大改善了施工作业环境和通风条件，为满足该Ⅰ级高风险长大隧道总工期要求奠定了坚实基础。',
                    right_img:require('../../imgs/cases/ct/9.png'),
                    imgs:[require('../../imgs/cases/2/1.png'),require('../../imgs/cases/2/2.png'),require('../../imgs/cases/2/3.png'),require('../../imgs/cases/2/4.png'),require('../../imgs/cases/2/5.png'),require('../../imgs/cases/2/6.png'),require('../../imgs/cases/2/7.png'),require('../../imgs/cases/2/8.png'),require('../../imgs/cases/2/9.png'),]
                },
                 {
                    img_url:require('../../imgs/cgal/9.png'),
                    title:'老挝孟村二号隧道',
                    title_a:'中老铁路孟村二号隧道顺利贯通',
                    text:'2020年1月7日，由中铁八局三公司中老铁路三标二分部负责施工的中老铁路孟村二号隧道顺利贯通。至此，项目部负责施工段内隧道全部贯通。孟村二号隧道的顺利贯通标志着项目部施工里程段进入收尾阶段，保证了中老铁路架梁工期，为线上工程展开奠定了坚实基础。',
                    text_b:'中老铁路孟村二号隧道位于老挝琅勃拉邦省湄公河半岛无人区，全长5890米，三次穿越断层带，地质情况复杂，施工难度极大，为Ⅰ级高风险隧道，是中老铁路线上控制性和重点隧道工程之一。该隧道由于出口地形狭窄，不具备工作面进洞条件，且会宴河车站双线456米布置进入隧道。为克服该施工困难，满足工期要求，在隧道出口设置横洞240米于线路前进方向左侧，通过打通横洞进入正洞施工。',
                    text_c:'为解决该重难点隧道技术攻关，三公司磨万二分部精心策划，不断优化施工技术方案，加强施工技术交底，严格遵守施工规范和标准、质量检查及验收标准，在上百余名中老工人的共同努力下，最快速度推进工程施工进度，实现了孟村二号隧道横洞至出口的顺利贯通，极大改善了施工作业环境和通风条件，为满足该Ⅰ级高风险长大隧道总工期要求奠定了坚实基础。',
                    right_img:require('../../imgs/cases/ct/9.png'),
                    imgs:[require('../../imgs/cases/2/1.png'),require('../../imgs/cases/2/2.png'),require('../../imgs/cases/2/3.png'),require('../../imgs/cases/2/4.png'),require('../../imgs/cases/2/5.png'),require('../../imgs/cases/2/6.png'),require('../../imgs/cases/2/7.png'),require('../../imgs/cases/2/8.png'),require('../../imgs/cases/2/9.png'),]
                }
            ]
        }
    },
    beforeMount(){
       this.aL=this.$route.query.userid
       console.log( this.aL);
    },
    methods:{
    yj(){
      this.$router.push('/Hardware')
      this.$emit('childFn', '/Hardware')
      document.body.scrollTop=0
      window.localStorage.setItem('url_address','/Hardware')
    },
    al(){
      this.$router.push('/success')
      this.$emit('childFn', '/success')
      document.body.scrollTop=0
      window.localStorage.setItem('url_address','/success')
    },
    }
}
</script>

<style lang="less" scoped>
.sadlad{
  background: url(../../imgs/background.png) no-repeat;
  background-size: 100%;
  position: relative;
}
.box_m{
  width: 1200px;
  margin: 0 auto;
}
.img_t{
    text-align: left;
    margin-top: 213px;
    display: flex;
    margin-bottom:60px;
    img{
        width: 1088px;
        height: 717px;
    }
    span{
        width: 8px;
        height: 272px;
        display: block;
        margin-left: 50px;
        margin-top: 155px;
        margin-right: 24px;
        background-color: #1667A8;
    }
    .qi{
        margin-top: 60px;
    }
    .ba{
        margin-top: 107px;
    }
    .liu{
        margin-top: 14px;
    }
    p{
        font-size: 48px;
        width: 46px;
        line-height: 48px;
    }
}
.a{
    text-align:right;
    overflow: hidden;
    .title{
        font-size: 36px;
        margin-bottom: 36px;
        width: 743px;
        float: right;
        text-align: left;
    }
    .text{
        width: 100%;
        display: block;
        font-size: 24px;
        color: #CCCCCC;
        width: 743px;
        float: right;
        text-align: left;
        margin-right: 0;
    }
}
.b{
    width: 728px;
    font-size: 24px;
    color: #CCCCCC;
    text-align: left;
    margin-top: 198px;
    line-height: 42px;
}
.right_i{
    position: absolute;
    right: 0;
    top: 1893px;
    display: flex;
    img{
        width: 568px;
        height: 867px;
    }
    div{
        position: absolute;
        bottom: 0;
        left: -80px;
    }
    span{
        width: 8px;
        height: 150px;
        display: block;
        margin-left: 50px;
        margin-top: 155px;
        margin-right: 24px;
        background-color: #1667A8;
    }
    p{
        font-size: 48px;
        width: 46px;
        line-height: 48px;
        position: absolute;
        bottom: 0;
        right:60px;
    }
}
.img_list{
    margin-top: 620px;
    p{
        font-size: 40px;
    }
    div{
        margin-top: 72px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap:wrap;
        img{
            width: 380px;
            height: 380px;
            margin-bottom: 30px;
        }
    }
}
.Small{
  margin-top: 280px;
  width: 100%;
  height: 352px;
  padding-bottom: 28px;
  background: url(../../imgs/diqiu.png) no-repeat center center;
  }
</style>
