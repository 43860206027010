<template>
  <div class="box sadlad">
    <div class="banner">
      <img src="../imgs/xiazai/banner.png" alt="鹭涛产品" class="banner">
    </div>
    <div class="m_box">
      <img src="../imgs/xiazai/title.png" alt="" class="title_img">
      <div class="tu">
        <div class="left">
          <img src="../imgs/xiazai/1.png" alt="" class="a">
          <img src="../imgs/xiazai/2.png" alt="" class="b">
        </div>
        <div class="right">
          <div class="a">
            <img src="../imgs/xiazai/xcx.png" alt="">
            <p>小程序扫码使用</p>
          </div>
          <div>
            <img src="../imgs/xiazai/az.png" alt="">
            <p>安卓端app扫码下载</p>
          </div>
        </div>
      </div>
      <div class="text">
        <ul>
          <li>
            <div class="title"><img src="../imgs/xiazai/ty.png" alt=""> 智能进度监测</div>
            <p>鹭涛智控工程管理系统能帮助我们更高效的管理多项目管理、工程进度管理、现场管理、工序衔接、数据汇总。</p>
          </li>
           <li>
            <div class="title"><img src="../imgs/xiazai/ty.png" alt=""> 施工安全汇报</div>
            <p>提供两台阶、CD、CRD等多种开挖法的沉降观测上报，把控施工的安全监控环节。达到预设沉降阈值提示警报。</p>
          </li>
           <li>
            <div class="title"><img src="../imgs/xiazai/ty.png" alt=""> 远程查看定位</div>
            <p>人员定位手机APP端，远程查看人员定位、报警、SOS、轨迹回放等。不被硬件限制，无需在值班室把控现场人员状况。</p>
          </li>
           <li>
            <div class="title"><img src="../imgs/xiazai/ty.png" alt=""> 视频监控查看</div>
            <p>隧道内摄像头与云端服务器连接，在任何有网络的地方查看实时洞内施工情况，可以更快捷高效处置隧道内的事宜。</p>
          </li>
           <li>
            <div class="title"><img src="../imgs/xiazai/ty.png" alt=""> 自动生成数据</div>
            <p>管理者可自定义时间、项目、报表类型等自动生成表格。云端存储安全，何时何地皆能调取查看、分享打印、汇总归档。</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="Small">
      <ul>
        <li>
          <div class="title">联系我们</div>
          <span>联系电话：400-990-8082</span>
          <span>电子邮箱：sc_lutao@163.com</span>
          <span>地址：成都市高新区中海海科大厦</span>
        </li>
        <li>
          <div class="title">关于鹭涛</div>
          <span>公司介绍</span>
          <span>硬件设备</span>
          <span>成功案例</span>
        </li>
        <li class="ewm">
          <img src="../imgs/ewm.png" alt="鹭涛小程序">
          <div>扫码使用微信小程序</div>
        </li>
      </ul>
      <div class="bah">
        Copyright © 2015 - 2018四川鹭涛智控科技有限公司All Rights Reserved <a href="https://beian.miit.gov.cn">蜀ICP备2020034987号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
      return {
        title: '四川鹭涛智控科技软件下载',
        meta: [
          { vmid: 'description', name: 'description', content: '四川鹭涛智控科技有限公司是一家专注于机械、环保等大型工程信息支持的高新技术企业。公司由电子科技大学、四川大学、澳门大学等多位信息技术专家和大型工程等专业人士组成。公司设置总经办、销售部、财务部、研发部、人力资源部等，目前在职员工人70人' },
        ],
      };
    },
    data(){
        return{

        }
    }
}
</script>

<style lang="less" scoped>
.sadlad{
  background: url(../imgs/background.png) no-repeat;
  background-size: 100%;
}
.m_box{
  width: 1200px;
  margin: 0 auto;
}
.title_img{
  margin-top: 150px;
}
.tu{
  display: flex;
  align-items: center;
  height: 490px;
  margin-top: 86px;
  .left{
    height: 490px;
    position: relative;
    width: 718px;
    img{
      position: absolute;
    }
    .a{
      left: 166px;
    }
    .b{
      left: 0;
      bottom:-80px;
    }
  }
  .right{
    display: flex;
    align-items: center;
    img{
      width: 150px;
      height: 150px;
    }
    div{
      margin-left: 109px;
      p{
        font-size: 18px;
        margin-top: 34px;
      }
    }
    .a{
      margin-left: 31px;
    }
  }
}
.text{
  z-index: 9;
  position: relative;
  ul{
    display: flex;
    justify-content: space-between;
    li{
      .title{
        font-size: 24px;
        img{
          margin-right: 8px;
        }
      }
      p{
        font-size: 18px;
        width: 171px;
        color: #999999;
        margin-top: 34px;
        line-height: 30px;
        text-align: left;
      }
    }
  }
}
</style>