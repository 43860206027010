<template>
  <div class="home">
    <div class="box">
      <div class="title_a">
      <img src="../imgs/log.png" alt="鹭涛">
      <ul>
        <li  @click="go(item)" :class="[url_d==item.address?'xz':'']"  v-for="(item,i) in tabar_list" :key="i">
                {{item.name}}
        </li>
      </ul>
    </div>
      <div class="nr">
        <router-view @childFn="parentFn" />
      </div>
    </div>
   <!-- 小字信息 -->
    <div class="Small" v-show="url_d!='/Software'&&url_d!='/cases'">
      <ul>
        <li>
          <div class="title">联系我们</div>
          <span>联系电话：400-990-8082</span>
          <span>电子邮箱：sc_lutao@163.com</span>
          <span>地址：成都市高新区中海海科大厦</span>
        </li>
        <li>
          <div class="title">关于鹭涛</div>
          <span>公司介绍</span>
          <span @click="yj">硬件设备</span>
          <span @click="al">成功案例</span>
        </li>
        <li class="ewm">
          <img src="../imgs/ewm.png" alt="鹭涛小程序">
          <div>扫码使用微信小程序</div>
        </li>
      </ul>
      <div class="bah">
        Copyright © 2015 - 2018四川鹭涛智控科技有限公司All Rights Reserved <a href="https://beian.miit.gov.cn">蜀ICP备2020034987号-1</a>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div class="fh" v-show="show_a">
        <div @click="fh(1)">
          <img src="../imgs/fhtop.png" alt="回到顶部">
          <p>回到顶部</p>
        </div>
        <div v-if="url_d!='/'" @click="fh(2)">
          <img src="../imgs/fhhome.png" alt="回到首页">
          <p>回到首页</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  metaInfo() {
      return {
        title: '四川鹭涛智控科技',
        meta: [
          { vmid: 'description', name: 'description', content: '四川鹭涛智控科技有限公司是一家专注于机械、环保等大型工程信息支持的高新技术企业。公司由电子科技大学、四川大学、澳门大学等多位信息技术专家和大型工程等专业人士组成。公司设置总经办、销售部、财务部、研发部、人力资源部等，目前在职员工人70人' },
        ],
      };
    },
  data(){
    return{
      tabar_list:[
        {
          name:'首页',
          address:'/'
        },
        {
          name:'产品',
          address:'/product'
        },
        {
          name:'硬件系统',
          address:'/Hardware'
        },
        {
          name:'成功案列',
          address:'/success'
        },
        {
          name:'软件下载',
          address:'/Software'
        },
        // {
        //   name:'登陆系统',
        //   address:'/land'
        // },
      ],
      url_d:'/',
      show_a:false
    }
  },
  methods:{
    go(item){
      this.url_d=item.address
      this.$router.push(item.address)
      window.localStorage.setItem('url_address',item.address)
    },
    yj(){
      this.$router.push('/Hardware')
      this.url_d='/Hardware'
      document.body.scrollTop=0
      window.localStorage.setItem('url_address','/Hardware')
    },
    al(){
      this.$router.push('/success')
      this.url_d='/success'
     document.body.scrollTop=0
      window.localStorage.setItem('url_address','/success')
    },
    // 返回顶部或首页
    fh(i){
      if (i==1) {
        this.show_a=false
        let a=document.body.scrollTop/20
        var aa= window.setInterval(function () {
          document.body.scrollTop-=a
          if (document.body.scrollTop==0) {
            window.clearInterval(aa)
          }
        },15)
      }else{
        this.$router.push('/');
        this.url_d='/'
        document.body.scrollTop=0
        window.localStorage.setItem('url_address',this.url_d)
      }
    },
    // 页面滚动监听
    handleScroll(){
      if (document.body.scrollTop>=800) {
        this.show_a=true
      }else{
        this.show_a=false
      }
    },
    // 子组件传值
    parentFn(payload){
      this.url_d=payload
      document.body.scrollTop=0
      window.localStorage.setItem('url_address',this.url_d)
    }
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll) 
  },
  beforeMount(){
    if (window.localStorage.getItem("url_address")!=null) {
      this.url_d=window.localStorage.getItem("url_address")
    }
  }
}
</script>

<style lang="less" scoped>
.home{
  width: 100%;
  background: url(../imgs/background.png) no-repeat center center;
  background-size: cover;
  color: #ffffff;
  .box{
    width: 100%;
    // max-width: 1920px;
    margin: 0 auto;
  }
}
// 标题栏
.title_a{
  width: 100%;
  height: 131px;
  display: flex;
  align-items: center;
  background: url(../imgs/title.png) no-repeat center center;
  background-size: cover;
  img{
    width: 174px;
    height: 58px;
    margin-left: 15%;
  }
  ul{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    margin-left: 89px;
    width: 80%;
    max-width: 854px;
    justify-content: space-between;
    li{
      transition: all .5s;
      padding: 4px 34px;
      box-sizing: border-box;
      border: 2px solid rgba(1, 1, 1, 0);
    }
    li:hover{
      cursor:pointer;
      padding: 4px 34px;
      border-radius: 16px;
      border: 2px solid #00E7FE;
      color: #00E7FE;
      box-sizing: border-box;
    }
    .xz{
      padding: 4px 34px;
      border-radius: 16px;
      border: 2px solid #00E7FE;
      color: #00E7FE;
      box-sizing: border-box;
    }
  }
}
// 右边返回图表
.fh{
  position: fixed;
  right: 0;
  bottom: 100px;
  div{
    width: 100px;
    height: 100px;
    background-color: #1667A8;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;
    img{
      display: block;
    }
    p{
      width: 100%;
    }
  }
  div:hover{
    cursor:pointer;
  }
}
</style>

<style lang="less">
// 小字 公用
.Small{
  margin-top: 280px;
  width: 100%;
  height: 352px;
  padding-bottom: 28px;
  background: url(../imgs/diqiu.png) no-repeat center center;
  background-size: cover;
  padding-top: 100px;
  ul{
    width: 1200px;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    li{
      text-align: left;
      .title{
        padding:0 20px;
        font-size: 20px;
        padding-bottom: 13px;
        border-bottom: 1px solid #ffffff;
        width: 123px;
        margin-bottom: 18px;
      }
      span{
        display: block;
        margin-top: 14px;
        font-size: 14px;
        color: #92B9E1;
      }
    }
    li:hover{
      cursor:pointer;
    }
    .ewm{
      img{
        width: 150px;
        height: 150px;
      }
      div{
        font-size: 16px;
        margin-top: 12px;
      }
    }
  }
  .bah{
    font-size: 12px;
    color: #8DAAC8;
    margin: 0 auto;
    margin-top: 45px;
	a{
		color: #8DAAC8;
	}
  }
}
.banner{
  width: 100%;
}
</style>