<template>
  <div class="box">
    <!-- 成功案列 -->
    <div class="banner">
      <img src="../imgs/cgal/banner.png" alt="鹭涛产品" class="banner">
    </div>
    <div class="box_m">
      <img src="../imgs/cgal/title.png" alt="鹭涛成功案列" class="title_img">
      <!-- 案列 -->
      <div class="Caselist"  v-for="(item,i) in list" :key="i">
        <img :src="item.url" :alt="item.title" class="left" v-if="(i%2==0)">
        <div class="text_box">
          <h3>{{item.title}}</h3>
          <div>
            <p>{{item.text}}</p>
            <div class="ckxq" @click="go(i)">查看详情</div>
            <span>时间：{{item.time}}</span>
          </div>
        </div>
        <img :src="item.url" alt="西武陵隧道石山横洞" v-if="(i%2!=0)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
      return {
        title: '四川鹭涛智控科技成功案列',
        meta: [
          { vmid: 'description', name: 'description', content: '四川鹭涛智控科技有限公司是一家专注于机械、环保等大型工程信息支持的高新技术企业。公司由电子科技大学、四川大学、澳门大学等多位信息技术专家和大型工程等专业人士组成。公司设置总经办、销售部、财务部、研发部、人力资源部等，目前在职员工人70人' },
        ],
      };
    },
    data(){
        return{
          list:[
            {
              title:'西武陵隧道石山横洞',
              url:require('../imgs/cgal/1.png'),
              text:'西武岭隧道位于安徽省黄山市黟县与祁门县交界处，全长12623.8米。其中石山横洞...',
              time:'2019年9月'
            },
            {
              title:'昌景黄新庄横洞',
              url:require('../imgs/cgal/2.png'),
              text:'西武岭隧道设置4座辅助坑道，新庄横洞是其中之一。',
              time:'2019年9月'
            },
            {
              title:'昌景黄霭山隧道',
              url:require('../imgs/cgal/3.png'),
              text:'西武岭隧道位于安徽省黄山市黟县与祁门县交界处，全长12623.8米。',
              time:'2019年9月'
            },
            {
              title:'叙毕斑竹林斜井',
              url:require('../imgs/cgal/4.png'),
              text:'斑竹林隧道目前斜井、横洞已贯通，现共有6个工作面同时掘进，开累掘进5896米...',
              time:'2019年9月'
            },
            {
              title:'昌景黄上村隧道',
              url:require('../imgs/cgal/5.png'),
              text:'景黄铁路黟县西递上村隧道为单洞单线隧道，隧道全长3325.21m。',
              time:'2019年9月'
            },
            {
              title:'贵阳杨中隧道',
              url:require('../imgs/cgal/6.png'),
              text:'杨中隧道全长200米，设计行车速为60公里/小时，为双向6车道城市主干道隧道。',
              time:'2019年9月'
            },
            {
              title:'昌景黄西武陵出口',
              url:require('../imgs/cgal/7.png'),
              text:'西武岭隧道位于安徽省黄山市黟县与祁门县交界处，全长12623.8米。',
              time:'2019年9月'
            },
            {
              title:'昌景黄竹林下斜井',
              url:require('../imgs/cgal/8.png'),
              text:'西武岭隧道位于安徽省黄山市黟县与祁门县交界处，全长12623.8米，其中竹下林斜...',
              time:'2019年9月'
            },
            {
              title:'昌景黄陶家林斜井',
              url:require('../imgs/cgal/9.png'),
              text:'西武岭隧道位于安徽省黄山市黟县与祁门县交界处，全长12623.8米，其中陶家岭斜...',
              time:'2019年9月'
            },
            {
              title:'老挝孟村二号隧道',
              url:require('../imgs/cgal/10.png'),
              text:'孟村二号隧道全长5874米，座落于湄公河半岛“无人区”，属于1级高风险隧道，采用...',
              time:'2019年9月'
            }
          ]
        }
    },
    methods:{
      go(i){
        // console.log(i);
        this.$router.push({path:'/cases',query:{userid:i}})
        this.$emit('childFn', '/cases');
      },
    }
}
</script>

<style lang="less" scoped>
.box_m{
  width: 1200px;
  margin: 0 auto;
}
.title_img{
  margin-top:150px;
  margin-bottom: 26px;
}
// 案列
.Caselist{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  .left{
     margin-right: 92px;
  }
  .text_box{
    height: 422px;
    width: 468px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap:wrap;
    text-align: left;
    h3{
      font-size: 32px;
      width: 100%;
    }
    div{
      font-size: 24px;
      color: #CCCCCC;
      .ckxq{
        font-size: 18px;
        color: #ffffff;
        border: 1px solid #00FFFF;
        width: 160px;
        height: 46px;
        text-align: center;
        line-height: 46px;
        margin:50px 0 ;
      }
      .ckxq:hover{
        cursor:pointer;
      }
    }
  }
}
</style>